"use client";
import Link from 'next/link'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Menu } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Badge } from "@/components/ui/badge"
import { Store } from "@/utils/interfaces/interfaces"
import { useEffect, useState } from "react"
import { createClient } from "@/utils/supabase/client"
import HeaderAuth from './header-auth'
import { usePathname } from 'next/navigation'
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetTitle,
} from "@/components/ui/sheet"
import { onCartUpdated } from '@/utils/events/cartEvents';

interface NavProps {
  domain?: string;
  settings?: Partial<Store>;
  initialIsOwner?: boolean;
}

export function Nav({ domain, settings, initialIsOwner = false }: NavProps) {
  const [isOwner, setIsOwner] = useState(initialIsOwner);
  const [user, setUser] = useState<any>(null);
  const [cartCount, setCartCount] = useState(0);
  const supabase = createClient();
  const pathname = usePathname();

  // Add function to fetch cart count
  const fetchCartCount = async () => {
    try {

      const response = await fetch('/api/cart');
      if (!response.ok) {

        return;
      }
      const data = await response.json();

      
      const count = (data.items || []).reduce((sum: number, item: any) => sum + (item.quantity || 0), 0);

      setCartCount(count);
    } catch (error) {

    }
  };

  useEffect(() => {
    

    fetchCartCount();
    
    // Use onCartUpdated instead of window event listener
    onCartUpdated(() => {

      fetchCartCount();
    });
    
    // Set up real-time subscription for cart changes
    const channel = supabase
      .channel('cart_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'cart_items'
        },
        () => {

          fetchCartCount();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  // Add a function to check ownership
  const checkOwnership = async (userId: string) => {

    
    if (!userId || !domain) {

      return false;
    }
    
    try {
      const { data: store, error } = await supabase
        .from('stores')
        .select('user_id')
        .eq('domain', domain)
        .single();
        
      if (error) {

        return false;
      }
      

      
      return store?.user_id === userId;
    } catch (err) {

      return false;
    }
  };

  useEffect(() => {

    
    // Single session check
    supabase.auth.getSession().then(({ data: { session } }) => {
 
      
      setUser(session?.user || null);
      if (session?.user) {
        checkOwnership(session.user.id).then((isOwner) => {
          setIsOwner(isOwner);
        });
      } else {
        setIsOwner(false);
      }
    });

    // Subscribe to auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {

      
      setUser(session?.user || null);
      
      if (session?.user) {
        const ownerStatus = await checkOwnership(session.user.id);
        console.log("[Nav] Updated ownership status:", ownerStatus);
        setIsOwner(ownerStatus);
      } else {
        setIsOwner(false);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [domain, supabase]);

  const NavigationItems = () => (
    <>
      {/* Customer Navigation */}
      <div className="flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0 md:pr-4 md:border-r">
        <Link href={`/shop`}>
          <Button 
            variant="ghost" 
            className={`w-full justify-start ${settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : ''}`}
          >
            Shop
          </Button>
        </Link>
        {user && (
          <Link href={`/orders`}>
            <Button 
              variant="ghost" 
              className={`w-full justify-start ${settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : ''}`}
            >
              Order History
            </Button>
          </Link>
        )}
      </div>

      {/* Store Owner Navigation */}
      {isOwner && (
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0 md:pl-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="ghost" 
                size="sm"
                className={`w-full justify-start ${settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}`}
              >
                Store Settings
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent 
              align="end"
              className={settings?.background_color ? `background-color: ${settings.background_color}` : ''}
            >
              <DropdownMenuItem 
                asChild
                className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
              >
                <Link href={`/shop/settings`}>Edit Settings</Link>
              </DropdownMenuItem>
              <DropdownMenuItem 
                asChild
                className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
              >
                <Link href={`/shop/settings`}>Edit Landing Page</Link>
              </DropdownMenuItem>
              <DropdownMenuItem 
                asChild
                className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
              >
                <Link href={`/shop/settings/landingpage/preview`}>Preview Landing Page</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="ghost" 
                size="sm"
                className={`w-full justify-start ${settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}`}
              >
                Products
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent 
              align="end"
              className={settings?.background_color ? `background-color: ${settings.background_color}` : 'bg-white'}
            >
              <DropdownMenuItem 
                asChild
                className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
              >
                <Link href={`/products/create`}>Create Product</Link>
              </DropdownMenuItem>
              <DropdownMenuItem 
                asChild
                className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
              >
                <Link href={`/products`}>Product Library</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="ghost" 
                size="sm"
                className={`w-full justify-start ${settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}`}
              >
                Analytics
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent 
              align="end"
              className={settings?.background_color ? `background-color: ${settings.background_color}` : 'bg-white'}
            >
              <DropdownMenuItem 
                asChild
                className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
              >
                <Link href={`/sales`}>Sales Dashboard</Link>
              </DropdownMenuItem>
              {process.env.NODE_ENV === 'development' && (
                <DropdownMenuItem 
                  asChild
                  className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                >
                  <Link href={`/google/ads`}>Google Ads</Link>
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
    </>
  )

  return (
    <nav 
      className={`border-b relative z-50 ${settings?.background_color ? `background-color: ${settings.background_color}` : 'bg-white'}`}
    >
      <div className="flex items-center justify-between max-w-7xl mx-auto px-4 h-16">
        {/* Mobile Menu */}
        <div className="md:hidden">
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="sm">
                <Menu className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent className="w-[300px] sm:w-[400px]">
              <SheetTitle>Navigation Menu</SheetTitle>
              <div className="flex flex-col space-y-4 mt-4">
                <NavigationItems />
              </div>
            </SheetContent>
          </Sheet>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-4">
          <NavigationItems />
        </div>

        {/* Right side navigation (always visible) */}
        <div className="flex items-center space-x-4">
          <Link href={`/`}>
            <Button 
              variant="ghost" 
              size="sm"
              className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
            >
              Home
            </Button>
          </Link>
          <HeaderAuth settings={settings} user={user} />
          <Link href={`/cart`}>
            <Button 
              variant="ghost" 
              size="sm"
              className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
            >
              Cart
              {cartCount > 0 && (
                <span 
                  className="ml-1 text-xs font-medium text-green-600"
                >
                  ({cartCount.toString()})
                </span>
              )}
            </Button>
          </Link>
        </div>
      </div>
    </nav>
  )
}