"use client";

import { signOutAction } from "@/app/domain/[domain]/domain-actions";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { Store } from "@/utils/interfaces/interfaces";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useRouter } from 'next/navigation';

interface HeaderAuthProps {
  settings?: Partial<Store>;
  user?: any;
}

export default function HeaderAuth({ settings, user }: HeaderAuthProps) {
  const router = useRouter();

  const handleSignOut = async () => {
    try {
      console.log('Starting sign out process...');
      await signOutAction();
      console.log('Sign out action completed successfully');
      router.push('/sign-in');
      window.location.reload();
    } catch (error) {
      if ((error as any)?.message === 'NEXT_REDIRECT') {
        window.location.reload();
        return;
      }
      console.error('Sign out error:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
    }
  };

  return user ? (
    <div className="flex items-center gap-4">
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center gap-2 outline-none">
          <span className={settings?.font_color ? `text-[${settings.font_color}]` : ''}>
            Hey, {user.email}!
          </span>
          <ChevronDown className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem asChild>
            <Link href="/user/settings">Account Settings</Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <button onClick={handleSignOut} className="w-full text-left">
              Sign out
            </button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  ) : (
    <div className="flex gap-2">
      <Button 
        asChild 
        size="sm" 
        variant="outline"
        className={settings?.primary_color 
          ? `border-[${settings.primary_color}] text-[${settings.primary_color}] hover:bg-[${settings.primary_color}]/10` 
          : ''}
      >
        <Link href="./sign-in">Sign in</Link>
      </Button>
      <Button 
        asChild 
        size="sm" 
        variant="default"
        className={settings?.primary_color 
          ? `bg-[${settings.primary_color}] hover:bg-[${settings.primary_color}]/90` 
          : ''}
      >
        <Link href="./sign-up">Sign up</Link>
      </Button>
    </div>
  );
}
